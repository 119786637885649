import React from "react";
import ticoLogoReg from "../../images/Tico-Logo_registered.svg";
import { Link } from "react-router-dom";
import { LinkedinFilled } from "@ant-design/icons";

const Footer = () => {
  return (
    <>
      <div className="container-fluid footer p-0">
        <div className="container">
          <div className="row footer foot pt-5">
            <div className="col-12 col-md-3">
              <a href="/">
                {" "}
                <img
                  className="foot-logo"
                  src={ticoLogoReg}
                  alt="Tico company logo"
                />
              </a>
            </div>
            <div className="col-6 col-md-3">
              <Link to="/solutions">
                <p>Solutions</p>
              </Link>
              <Link to="/product-application">
                <p>Product Applications</p>
              </Link>
              <Link to="/product-parameters">
                <p>Product Parameters</p>
              </Link>
              <Link to="/industries">
                <p>Industries</p>
              </Link>
            </div>
            <div className="col-6 col-md-3">
              <Link to="/services">
                <p>Services</p>
              </Link>
              <Link to="/contact-us">
                <p>Contact Us</p>
              </Link>
              <Link to="/distributors">
                <p>Distributors</p>
              </Link>
              <Link to="/about-us">
                <p>About TICO</p>
              </Link>
            </div>
            <div className="col-12 col-md-3 d-flex flex-column">
              <a
                href="https://www.linkedin.com/company/74491586/"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedinFilled className="pr-2 link-icon float-left" />
                <p className="float-left pt-1">TICO</p>
              </a>
              <a
                href="https://www.linkedin.com/in/anthony-o-mahony-7731868a/"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedinFilled className="pr-2 link-icon float-left" />
                <p className="float-left pt-1">Anthony</p>
              </a>
              <a
                href="https://www.linkedin.com/in/allen-van-der-merwe-42a77555/"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedinFilled className="pr-2 link-icon float-left" />
                <p className="float-left pt-1">Allen</p>
              </a>
              <a
                href="https://www.linkedin.com/in/gemma-ford-3824891a1/"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedinFilled className="pr-2 link-icon float-left" />
                <p className="float-left pt-1">Gemma</p>
              </a>
            </div>
          </div>
        </div>
        <div className="pt-5 pb-3">
          <div className="col-12 text-center foot">
            <p className="d-inline text-danger pr-3 small">
              TICO 2025. All rights reserved.
            </p>
            <Link to="/terms-of-use">
              <p className="d-inline pr-3 small">Terms of Use</p>
            </Link>
            <Link to="/privacy-policy">
              <p className="d-inline pr-3 small">Privacy Policy</p>
            </Link>
            <Link to="/cookie-policy">
              <p className="d-inline pr-3 small">Cookie Policy</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
